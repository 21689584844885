import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/layout';
import '../assets/styles/pages/about.scss';

const AboutUs = ({ data }) => (
  <Layout showTitleRow="About us" pageClass="about-page-wrapper">
    <HelmetDatoCms seo={data.about.seoMetaTags} />
    <div className="container about-us-wrapper">
      <div className="row">
        <div
          className="one-quarter sidebar"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data.about.sidebar,
          }}
        />
        <div
          className="two-quarters body-content-wrapper"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data.about.body,
          }}
        />
      </div>
    </div>
  </Layout>
);

AboutUs.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default AboutUs;

export const query = graphql`
  query AboutQuery {
    about: datoCmsAbout {
      body,
      sidebar,
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
